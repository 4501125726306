import React from "react";
import OverviewActions from "./OverviewActions";
import Services from "./Services";

function Overview() {
    return (
        <>
            <OverviewActions />
            <h1 className="text-2xl font-semibold">Overview</h1>
            <Services />
        </>
    )
}

export default Overview;
