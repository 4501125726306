import React, { useContext } from "react";
import { BACKEND_HOST } from "../config";
import UserContext from "../contexts/UserContext";
import { getDefaultHeaders } from "../utils/fetch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Button from "./Button";
import { ModalContext } from "./Modal";

export default function DeleteServiceAction({service, resetState}) {
    const {token} = useContext(UserContext)
    const [, dispatch] = useContext(ModalContext);

    const handleClick = () => {
        const modalData = {
            title: `Delete Service ${service.name}`,
            body: <div>
                <p>Do you really want to delete <span className="font-semibold">{service.name}</span>?</p>
                <p className="mt-5">
                    <span className="font-semibold">URL:</span> {service.url}<br />
                    <span className="font-semibold">ID:</span> {service.id}
                </p>
            </div>,
            actions: <>
                <Button label="Confirm" onClick={handleConfirm} />
                <Button label="Cancel" onClick={handleAbort} color="gray-200" textColor="gray-600" />
            </>
        }

        dispatch({type: "SET_MODAL", payload: modalData});
        dispatch({type: "SHOW"});

    }

    const handleAbort = () => {
        dispatch({type: "RESET_MODAL"});
    }

    const handleConfirm = () => {
        fetch(BACKEND_HOST + "/services/" + service.id + "/", {
            method: "DELETE",
            headers: getDefaultHeaders(token)
        }).then(response => {
            dispatch({type: "RESET_MODAL"});
            resetState();
        });
    }

    return (
        <Button
            onClick={handleClick}
            icon={<FontAwesomeIcon fixedWidth icon={faTrash} />}
            paddingClasses="px-2 py-1.5"
        />
    )
}
