import { useState } from "react";

export default function useUser() {
    const userData = JSON.parse(localStorage.getItem("me"));
    const emptyUser = {
        id: null,
        username: null,
        email: null,
        isAdmin: false,
        token: null
    }

    const [user, setUser] = useState(userData || emptyUser);

    const updateUser = data => {
        localStorage.setItem("me", JSON.stringify(data));
        setUser(data);
    };

    const setToken = token => {
        updateUser({...user, token: token});
    };

    const setUserData = data => {
        data.token = user.token;
        updateUser(data);
    };

    const resetUser = () => {
        updateUser(emptyUser);
        localStorage.removeItem("me");
    };

    return {
        user,
        setToken,
        setUser: setUserData,
        resetUser
    };
}
