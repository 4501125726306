import React from "react";
import { Link } from "react-router-dom";

function ButtonLink({label, target, icon = null, className = "",
                        paddingClasses = "px-5 py-1.5",
                        color = "blue-500", textColor = "white"
                    }) {
    return (
        <Link to={target} className={`bg-${color} text-${textColor} rounded-lg cursor-pointer ${paddingClasses} ${className}`}>
            {icon ? icon : ""}
            {label ? <span className={"font-light" + (icon ? " ml-2.5" : "")}>{label}</span> : ""}
        </Link>
    );
}

export default ButtonLink;
