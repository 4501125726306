import React from "react";
import AddServiceAction from "./AddServiceAction";

function OverviewActions() {
    return (
        <div className="text-right">
            <ul>
                <li><AddServiceAction /></li>
            </ul>
        </div>
    )
}

export default OverviewActions;
