import React from "react";
import ButtonLink from "./ButtonLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";

export default function EditServiceAction({serviceID}) {
    return (
        <ButtonLink
            target={`/services/${serviceID}/`}
            icon={<FontAwesomeIcon fixedWidth icon={faPen} />}
            paddingClasses="px-2 py-2"
        />
    )
}
