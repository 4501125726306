import React, {useEffect, useState, useRef} from "react";
import {VictoryChart, VictoryArea, VictoryAxis} from "victory";

function Service({service, data, status = null}) {
    const ref = useRef();
    const elemSize = ref.current ? {
        width: ref.current.clientWidth,
        height: ref.current.clientHeight
    } : {
        width: 400,
        height: 200
    };

    const [dimensions, setDimensions] = useState({
        width: elemSize.width,
        height: elemSize.height
    });

    const updateDimensions = () => {
        setDimensions({
            width: elemSize.width,
            height: elemSize.height
        })
    }

    useEffect(() => {
        window.addEventListener('resize', updateDimensions)
        updateDimensions();

        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
        // eslint-disable-next-line
    }, [elemSize.width, elemSize.height]);

    if(status === null) {
        if(data.length > 0) {
            status = data[data.length - 1] >= 0 ? "SUCCESS" : "ERROR";
        } else {
            status = "UNKNOWN";
        }
    }
    const statusColor = {
        ERROR: "red",
        SUCCESS: "green",
        UNKNOWN: "gray"
    }

    return (
        <div ref={ref} className={`w-full md:w-96 h-52 border-2 border-gray-300 my-5 md:m-10 relative bg-${statusColor[status]}-200`}>
            <VictoryChart width={dimensions.width} height={dimensions.height} maxDomain={{y: 500}} minDomain={{y: 0}} padding={{left: 20, bottom: 0, top: 10}}>
                <VictoryArea data={data} style={{
                    data: {
                        fill: statusColor[status]
                    }
                }}>
                </VictoryArea>
                <VictoryAxis tickFormat={() => ''} />
                <VictoryAxis dependentAxis style={{
                    tickLabels: {
                        fontSize: 9,
                        padding: 2
                    }
                }}/>
            </VictoryChart>
            <div className="absolute right-2 top-2 bg-gray-100 p-0.5 text-xs">
                {service.name} ({service.url})
            </div>
        </div>
    )
}

export default Service;
