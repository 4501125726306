import React from "react";

export function ErrorMessage({children}) {
    return (
        <div className="border-2 border-red-700 bg-red-300 text-red-800 p-3 text-sm mb-5">
            {children}
        </div>
    );
}

export function SuccessMessage({children}) {
    return (
        <div className="border-2 border-green-700 bg-green-300 text-green-800 p-3 text-sm mb-5">
            {children}
        </div>
    );
}

export function InfoMessage({children}) {
    return (
        <div className="border-2 border-yellow-700 bg-yellow-300 text-yellow-800 p-3 text-sm mb-5">
            {children}
        </div>
    );
}

function Message({type, children}) {
    switch(type) {
        case "error":
            return (
                <ErrorMessage>{children}</ErrorMessage>
            );
        case "success":
            return (
                <SuccessMessage>{children}</SuccessMessage>
            );
        default:
            return (
                <InfoMessage>{children}</InfoMessage>
            );
    }
}

export default Message;
