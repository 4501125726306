import React from "react";

function MainContainer({children}) {
    return (
        <div className="bg-white border border-gray-300 mt-10 p-5 shadow">
            {children}
        </div>
    )
}

export default MainContainer;
