import React from "react";

const UserContext = React.createContext({
    id: null,
    username: null,
    email: null,
    isAdmin: false,
    token: null
});

export default UserContext;
