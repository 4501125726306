import React, { useEffect } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";

import { BACKEND_HOST } from "./config";
import { getDefaultHeaders } from "./utils/fetch";
import UserContext from "./contexts/UserContext";
import useUser from "./hooks/useUser";
import Navbar from "./components/Navbar";
import Login from "./components/Login";
import Footer from "./components/Footer";
import MainContainer from "./components/MainContainer";
import Overview from "./components/Overview";
import ServiceEdit from "./components/ServiceEdit";
import RequiresLogin from "./components/RequiresLogin";
import RequiresAdmin from "./components/RequiresAdmin";
import Modal, { ModalStore } from "./components/Modal";

function App() {
    const {user, setUser, setToken, resetUser} = useUser();

    const handleLogout = () => {
        fetch(BACKEND_HOST + "/logout/", {
            headers: getDefaultHeaders(user.token)
        }).then(response => {
            resetUser()
        });
    };

    useEffect(()  => {
        if(user.token !== null) {
            fetch(BACKEND_HOST + "/me/", {
                headers: getDefaultHeaders(user.token)
            }).then(response => response.json()).then(data => {
                if(!data.error) {
                    localStorage.setItem("me", JSON.stringify(data))
                    setUser(data);
                } else {
                    resetUser();
                }
            }).catch(err => {
                resetUser();
            });
        }
    }, [user.token]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="flex flex-col min-h-screen text-gray-700">
            <UserContext.Provider value={user}>
                <ModalStore>
                    <Router>
                        <Navbar handleLogout={handleLogout} />
                        <div className="container relative mx-auto flex-grow mt-16">
                            <Switch>
                                <Route path="/login/">
                                    {user.token ? <Redirect to="/" /> : <Login setToken={setToken} />}
                                </Route>
                                <Route path="/admin/">
                                    <MainContainer>
                                        <RequiresAdmin>
                                            <h1 className="text-xl">Admin Area!</h1>
                                        </RequiresAdmin>
                                    </MainContainer>
                                </Route>
                                <Route path="/services/add/">
                                    <MainContainer>
                                        <RequiresLogin>
                                            <ServiceEdit />
                                        </RequiresLogin>
                                    </MainContainer>
                                </Route>
                                <Route path="/services/:serviceID/">
                                    <MainContainer>
                                        <RequiresLogin>
                                            <ServiceEdit />
                                        </RequiresLogin>
                                    </MainContainer>
                                </Route>
                                <Route path="/">
                                    <MainContainer>
                                        <RequiresLogin>
                                            <Overview />
                                        </RequiresLogin>
                                    </MainContainer>
                                </Route>
                            </Switch>
                        </div>
                        <Footer />
                    </Router>
                    <Modal />
                </ModalStore>
            </UserContext.Provider>
        </div>
    );
}

export default App;
