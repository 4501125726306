import React, { useReducer, createContext, useContext } from "react";

export default function Modal() {
    const [state] = useContext(ModalContext);

    return state.active ?
        (
            <div className="fixed h-full w-full bg-gray-800 bg-opacity-75">
                <div className="w-full mx-auto mt-0 sm:mt-20 sm:w-144 shadow">
                    <div className="text-lg bg-blue-500 rounded-t-lg px-5 py-3 text-gray-200 font-light">
                        {state.title}
                    </div>
                    <div className="bg-white p-5 text-gray-500">
                        {state.body}
                    </div>
                    <div className="flex space-x-2 justify-end border-t p-5 bg-white rounded-b-lg">
                        {state.actions}
                    </div>
                </div>
            </div>
        )
            :
        (
            ""
        )
}

const initialState = {
    active: false,
    title: null,
    body: null,
    actions: []
};

export const ModalContext = createContext(initialState);

const Reducer = (state, action) => {
    switch(action.type) {
        case "SHOW":
            return {...state, active: true};
        case "HIDE":
            return {...state, active: false};
        case "SET_TITLE":
            return {...state, title: action.payload};
        case "SET_BODY":
            return {...state, body: action.payload};
        case "SET_ACTIONS":
            return {...state, actions: action.payload};
        case "SET_MODAL":
            return {...state, ...action.payload};
        case "RESET_MODAL":
            return initialState;
        default:
            return state;
    }
};

export const ModalStore = ({children}) => {
    const [state, dispatch] = useReducer(Reducer, initialState);

    return (
        <ModalContext.Provider value={[state, dispatch]}>
            {children}
        </ModalContext.Provider>
    );
};
