import React from "react";

function Button({label, submit = false, onClick = () => {},
                    preventDefault = true, className = "", icon = null,
                    paddingClasses = "px-5 py-1.5",
                    color = "blue-500", textColor = "white"
                }) {
    return (
        <button type={submit ? "submit" : "button"}
                className={`bg-${color} text-${textColor} rounded-lg cursor-pointer ${paddingClasses} ${className}`}
                onClick={e => {
                    if(preventDefault) {
                        e.preventDefault();
                    }
                    onClick();
                }}
        >
            {icon ? icon : ""}
            {label ? <span className={"font-light" + (icon ? " ml-2" : "")}>{label}</span> : ""}
        </button>
    );
}

export default Button;
