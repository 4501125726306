import React, { useState, useEffect, useContext } from "react";
import UserContext from "../contexts/UserContext";
import { BACKEND_HOST, WEBSOCKET_HOST } from "../config";
import { getDefaultHeaders } from "../utils/fetch";
import Service from "./Service";
import EditServiceAction from "./EditServiceAction";
import DeleteServiceAction from "./DeleteServiceAction";


function Services() {
    const [services, setServices] = useState([]);
    const [serviceData, setServiceData] = useState({});
    const [serviceStatus, setServiceStatus] = useState({});

    const {token} = useContext(UserContext);
    const [reset, setReset] = useState(false);
    const [connected, setConnected] = useState(false);

    useEffect(() => {
        fetch(BACKEND_HOST + "/services/", {
            headers: getDefaultHeaders(token)
        }).then(response => response.json()).then(data => {
            if(!data.error) {
                setServices(data);
            }
        });
    }, [token, reset]);

    useEffect(() => {
        let socket;
        let expectedClose = false;

        const connect = () => {
            socket = new WebSocket(WEBSOCKET_HOST + "/services/status/");

            socket.onopen = e => {
                setConnected(true);
                socket.send("auth_token " + token);
            };

            socket.onmessage = e => {
                let data = e.data.split(" ");
                let cmd = data[0];
                let params = data.slice(1);

                switch(cmd) {
                    case "auth_success": {
                        socket.send("Hello! :)");
                        break;
                    }
                    case "auth_timeout": {
                        console.log("auth timeouted.");
                        break;
                    }
                    case "status":
                        const id = params[0];
                        const status = params[1];

                        setServiceStatus(prevState => ({...prevState, [id]: status}));

                        switch(status) {
                            case "SUCCESS":
                                setServiceData(prevState => {
                                    let data = [...prevState[id]] || [];
                                    data.push(parseInt(params[2]));
                                    data = data.length > 40 ? data.slice(1) : data;
                                    return {...prevState, [id]: data};
                                });
                                break;
                            case "ERROR":
                                setServiceData(prevState => {
                                    let data = [...prevState[id]] || [];
                                    data.push(-1);
                                    data = data.length > 40 ? data.slice(1) : data;
                                    return {...prevState, [id]: data};
                                });
                                break;
                            default:
                                console.log("Unknown Status");
                        }
                        break;
                    case "init":
                        const initValues = params[1].split(",").map(val => parseInt(val));
                        setServiceData(prevState => ({...prevState, [params[0]]: initValues}));
                        break;
                    default: {
                        console.log(e.data);
                    }
                }
            }

            socket.onclose = e => {
                setConnected(false);

                if(!expectedClose) {
                    console.log("Connection was closed. Trying to reconnect..")
                    setTimeout(() => {
                        connect();
                    }, 5000);
                }
            }
        };

        if(!connected && token) {
            connect();
        }

        return () => {
            if(socket) {
                expectedClose = true;
                socket.close();
                setConnected(false);
            }
        }

        // eslint-disable-next-line
    }, [token]);

    let resetState = () => {
        setReset(!reset);
    };

    return (
        <>
            <div className="flex flex-row items-center">
                <p>Socket-Status:</p>
                <div
                    className={`inline-block border-2 rounded-xl w-5 h-5 ml-2 bg-${connected ? "green" : "red"}-500 border-${connected ? "green" : "red"}-700`}
                />
            </div>
            <div className="flex flex-wrap">
                { services.map(service => (
                    <div key={service.id} className="relative">
                        <Service service={service} data={serviceData[service.id] || []} status={serviceStatus[service.id]} />
                        <ul className="absolute right-0 top-11">
                            <li>
                                <EditServiceAction serviceID={service.id} />
                            </li>
                            <li className="mt-3">
                                <DeleteServiceAction resetState={resetState} service={service} />
                            </li>
                        </ul>
                    </div>
                ))}
            </div>
        </>
    )
}

export default Services;
