import React from "react";

function TextInput({name, state, setState, className = "", label = null, obscured = false}) {
    return (
        <>
            {
                label ?
                    <label className="mb-2 block" htmlFor={"id_" + name}>{label}:</label>
                    :
                    ""
            }
            <input type={obscured ? "password" : "text"}
                   name={name}
                   id={"id_" + name}
                   className={"w-full p-1 block bg-white mx-auto border border-gray-300 rounded focus:outline-none focus:border-blue-300 " + className}
                   onChange={e => setState(e.target.value)}
                   value={state}
            />
        </>
    );
}

export default TextInput;
