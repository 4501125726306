import React from "react"

function Footer() {
    return (
        <div className="bg-black h-8 text-white py-1.5 px-10 text-xs text-right">
            v0.1 by alexsa.de
        </div>
    );
}

export default Footer;
