import React, { useContext } from "react";
import UserContext from "../contexts/UserContext";
import { Link } from "react-router-dom";

function Navbar({handleLogout}) {
    const user = useContext(UserContext);

    return (
        <header className="flex py-4 px-8 bg-blue-500 border-b border-blue-600 text-gray-200 shadow fixed w-full">
            <div className="flex-1 font-light">
                <Link to="/" className="text-xl">Argus</Link>
            </div>
            <div className="flex-1 text-right">
                {
                    user.id ?
                        <>
                            <span>{user.username}</span>
                            {user.isAdmin ? <Link className="ml-3" to="/admin/">Admin</Link> : ""}
                            <button type="button" className="ml-3" onClick={() => handleLogout()}>Logout</button>
                        </>
                        :
                        <Link to="/login/">Login</Link>
                }
            </div>
        </header>
    );
}

export default Navbar;