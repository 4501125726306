import React from "react";
import ButtonLink from "./ButtonLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

function AddServiceAction() {
    return (
        <ButtonLink
            target="/services/add/"
            label="Add Service"
            icon={<FontAwesomeIcon icon={faPlus} />}
        />
    )
}

export default AddServiceAction;
