import React, { useState } from "react";
import { BACKEND_HOST } from "../config";
import TextInput from "./TextInput";
import Button from "./Button";
import { ErrorMessage } from "./Message";

function Login({setToken}) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);

    const handleSubmit = e => {
        e.preventDefault();
        const token = btoa(username.toLowerCase() + ":" + password);
        fetch(BACKEND_HOST + "/login/", {
            method: "POST",
            headers: {
                "Authorization": "Basic " + token
            }
        }).then(response => response.json()).then(data => {
            if(!data.error) {
                setToken(data.value);
            } else {
                setError("Error: Wrong username/password.");
            }
        }).catch(err => {
            setError("Error: Could not connect to backend.");
        });
    };

    return (
        <div className="shadow bg-white w-96 px-5 py-6 max-w-full mx-auto mt-5 sm:mt-20 border border-gray-300">
            {
                error ?
                    <ErrorMessage>
                        {error}
                    </ErrorMessage>
                    :
                    ""
            }

            <form onSubmit={handleSubmit}>
                <TextInput name="username"
                           label="Username"
                           state={username}
                           setState={setUsername}
                           className="mb-4"
                />
                <TextInput name="password"
                           label="Password"
                           state={password}
                           setState={setPassword}
                           className="mb-5"
                           obscured={true}
                />
                <Button label="Login" submit={true} preventDefault={false} />
            </form>
        </div>
    );
}

export default Login;
