import React from "react";

function NoPermission() {
    return (
        <p>
            You don't have permission to access this page.
        </p>
    )
}

export default NoPermission;
