import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import UserContext from "../contexts/UserContext";

function RequiresLogin({children}) {
    const user = useContext(UserContext);

    return (
        <>
            {user.token ? children : <Redirect to="/login/" />}
        </>
    );
}

export default RequiresLogin;
