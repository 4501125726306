import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { BACKEND_HOST } from "../config";
import UserContext from "../contexts/UserContext";
import { getDefaultHeaders } from "../utils/fetch";
import TextInput from "./TextInput";
import Button from "./Button";
import Message from "./Message";

function ServiceEdit({id = null}) {
    const {serviceID} = useParams();
    const history = useHistory();

    const [message, setMessage] = useState({
        type: null,
        text: ""
    });

    const [service, setService] = useState({
        id: serviceID,
        name: "",
        url: ""
    });

    const {token} = useContext(UserContext);

    useEffect(() => {
        if(service.id) {
            fetch(BACKEND_HOST + "/services/" + service.id + "/", {
                headers: getDefaultHeaders(token)
            }).then(response => response.json()).then(data => {
                if(!data.error) {
                    setService(data);
                }
            });
        }
    }, [token, service.id])

    const setName = name => {
        setService({...service, name: name});
    };

    const setUrl = url => {
        setService({...service, url: url});
    };

    const createService = () => {
        fetch(BACKEND_HOST + "/services/", {
            method: "POST",
            headers: getDefaultHeaders(token),
            body: JSON.stringify(service)
        }).then(response => response.json()).then(data => {
            if(!data.error) {
                setService(data);
                setMessage({
                    type: "success",
                    text: "Service has been created."
                });
                history.push("/services/" + data.id + "/");
            } else {
                setMessage({
                    type: "error",
                    text: "Error: " + data.reason
                });
            }
        });
    };

    const updateService = () => {
        fetch(BACKEND_HOST + "/services/" + serviceID + "/", {
            method: "PATCH",
            headers: getDefaultHeaders(token),
            body: JSON.stringify(service)
        }).then(response => response.json()).then(data => {
            if(!data.error) {
                setService(data);
                setMessage({
                    type: "success",
                    text: "Service has been updated."
                });
            } else {
                setMessage({
                    type: "error",
                    text: "Error: " + data.reason
                });
            }
        });
    };

    const handleSubmit = e => {
        e.preventDefault();

        if(!serviceID) {
            createService();
        } else {
            updateService();
        }
    }

    return (
        <>
            {message.text ? <Message type={message.type}>{message.text}</Message> : ""}
            <h1 className="text-2xl font-semibold">{service.name || "New service"} (ID: {service.id || "-"})</h1>
            <div className="max-w-xl mt-5">
                <form onSubmit={handleSubmit}>
                    <TextInput className="mb-3" name="name" state={service.name} setState={setName} label="Name" />
                    <TextInput className="mb-5" name="url" state={service.url} setState={setUrl} label="Url" />
                    <Button submit={true} preventDefault={false} label={serviceID ? "Update" : "Create"} />
                </form>
            </div>
        </>
    )
}

export default ServiceEdit;
