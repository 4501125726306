import React, { useContext } from "react";
import UserContext from "../contexts/UserContext";
import NoPermission from "./NoPermission";
import RequiresLogin from "./RequiresLogin";

function RequiresAdmin({children}) {
    const user = useContext(UserContext);

    return (
        <RequiresLogin>
            {user.isAdmin ? children : <NoPermission />}
        </RequiresLogin>
    )
}

export default RequiresAdmin;
